import "./Form.scss"

import sendData from "../../service/sendForm"
import { useState } from "react"

const Form = () => {
	const [inpPhone, setInpPhone] = useState("")
	const [messageForm, setMessageForm] = useState(null)
	const [messageColor, setMessageColor] = useState("")

	const sendMessage = (event) => {
		event.preventDefault()
		if (inpPhone.length > 3) {
			setMessageForm(null)
			const currentDate = new Date();
			const options = {
				timeZone: 'Europe/Kiev',
				hour12: false,
				year: 'numeric',
				month: '2-digit',
				day: '2-digit',
				hour: '2-digit',
				minute: '2-digit'
			};
			const formattedDate = currentDate.toLocaleString('uk-UA', options);
			sendData(`<b>Заявка з сайту</b>\n\nДата: ${formattedDate}\n\nНомер телефону: ${inpPhone}`).then(data => {
				if (data.ok) {
					setMessageColor(true)
					setMessageForm("Заявку успішно відправлено!")

					setTimeout(() => {
						setMessageForm(null)
					}, 3000)
				} else {
					setMessageColor(false)
					setMessageForm("Щось пішло не так... Спробуйте пізніше!")
					setTimeout(() => {
						setMessageForm(null)
					}, 3000)
				}
			})
		} else {
			setMessageColor(false)
			setMessageForm("Ви маєте ввести номер телефону!")
			setTimeout(() => {
				setMessageForm(null)
			}, 3000)
		}
	}

	return (
		<div className="form" onSubmit={(event) => sendMessage(event)}>
			<p>Виникло питання? <br /> Або хочете замовити прибирання?</p>
			<p>Залиште номер і ми передзвонимо</p>
			<form>
				<input onChange={(event) => { setInpPhone(event.target.value) }} value={inpPhone} type="text" placeholder="Введіть номер телефону" />
				{messageForm && <p className={`form__message ${messageColor ? "green" : "red"}`}>{messageForm}</p>}
				<button className="btn">Чекаю дзвінок</button>
			</form>
		</div>
	)
}

export default Form

