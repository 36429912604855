import { useModal } from "../../service/ModalContext"

const Button = () => {
	const { activeModal, setActiveModal } = useModal();

	return (
		<button className="btn" onClick={() => setActiveModal(true)}>Замовити прибирання</button>
	)
}

export default Button