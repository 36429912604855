import "./Prices.scss"
import Button from "../Button/Button"

const Prices = () => {

	return (
		<section className="prices">
			<div className="container">
				<h2>У нас зрозумілі і прозорі ціни на клінінг</h2>
				<div className="row">
					<div className="prices__card">
						<div className="prices__card-top">
							<h3>Підтримуюче прибирання</h3>
						</div>
						<div className="prices__card-bottom">
							<p>від 900 грн</p>
							<Button />
						</div>
					</div>
					<div className="prices__card">
						<div className="prices__card-top">
							<h3>Генеральне прибирання</h3>
						</div>
						<div className="prices__card-bottom">
							<p>від 1500 грн</p>
							<Button />
						</div>
					</div>
					<div className="prices__card">
						<div className="prices__card-top">
							<h3>Після ремонту</h3>
						</div>
						<div className="prices__card-bottom">
							<p>від 3000 грн</p>
							<Button />
						</div>
					</div>
					<div className="prices__card">
						<div className="prices__card-top">
							<h3>Додаткові послуги</h3>
							<ul>
								<li><b>Миття вікон</b> - від 150 грн</li>
								<li><b>Хімчистка мʼяких меблів</b>  від 500 грн</li>
								<li><b>Дезінфекція</b> - від 2500 грн</li>
								<li>та інші</li>
							</ul>
						</div>
						<div className="prices__card-bottom">
							<p></p>
							<Button />
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Prices