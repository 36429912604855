import "./Hero.scss"
import { imageHero } from "../../service/image"
import Button from "../Button/Button"

const Hero = () => {
	return (
		<section className="hero">
			<div className="container">
				<div className="row">
					<div className="hero__image">
						<img src={imageHero} alt="MainImage" />
					</div>
					<div className="hero__descr">
						<h1 className="logo">Сяючий Будинок </h1>
						<p className="hero__subtitle">Клінінгова компанія №1 у Львові</p>
						<p className="hero__citation">Блиск і чистота <br />
							кожного куточка вашого будинку</p>
						<p className="hero__citation" style={{ fontStyle: "italic", fontFamily: "monospace" }}>При замовленні прибирання після ремонту <br /> ви отримуєте <span style={{ fontWeight: 900, color: "red" }}>ЗНИЖКУ -20%</span> на миття вікон</p>
						<Button />
					</div>
				</div>
			</div>
		</section>
	)
}

export default Hero