import { createContext, useState, useContext } from "react";

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
	const [activeModal, setActiveModal] = useState(false);

	return (
		<ModalContext.Provider value={{ activeModal, setActiveModal }}>
			{children}
		</ModalContext.Provider>
	);
};

export const useModal = () => useContext(ModalContext);
