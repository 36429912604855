import "./About.scss"
import Button from "../Button/Button"
import { imageAbout1, imageAbout2, imageAbout3 } from "../../service/image"

const About = () => {
	return (
		<section className="about">
			<div className="container">
				<div className="row">
					<div className="about__descr">
						<h2>Про нас</h2>
						<div className="about__descr-text row">
							<p>"Сяючий Будинок" - ваш надійний партнер у справжній чистоті. Ми не просто надаємо клінінгові послуги, ми створюємо блискучі приміщення, в яких ви будете раді працювати та жити. </p>
							<p>Наші професійні команди працюють з великою увагою до деталей і застосовують найкращі методи і засоби для досягнення бездоганного результату. </p>
							<p>Довірте нам свій будинок або офіс, і ми зробимо все можливе, щоб забезпечити його чистоту та порядок.</p>
						</div>
						<Button />
					</div>
					<div className="about__images row">
						<img src={imageAbout1} alt="about__images1" />
						<img src={imageAbout2} alt="about__images2" />
						<img src={imageAbout3} alt="about__images3" />
					</div>
				</div>
			</div>
		</section>
	)
}

export default About