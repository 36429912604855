import "./Advantages.scss"
import { imageAboutCard1, imageAboutCard2, imageAboutCard3, imageAboutCard4 } from "../../service/image"

const Advantages = () => {
	return (
		<section className="advantages">
			<div className="container">
				<div className="row">
					<div className="advantages__card">
						<img src={imageAboutCard1} alt="imageAboutCard1" />
						<h3>Кваліфіковані <br /> працівники</h3>
					</div>
					<div className="advantages__card">
						<img src={imageAboutCard2} alt="imageAboutCard2" />
						<h3>Якісна та безпечна <br /> еко-хімія</h3>
					</div>
					<div className="advantages__card">
						<img src={imageAboutCard3} alt="imageAboutCard3" />
						<h3>Професійна техніка</h3>
					</div>
					<div className="advantages__card">
						<img src={imageAboutCard4} alt="imageAboutCard4" />
						<h3>Працюємо з ПН по СБ <br /> від 9:00 до 22:00</h3>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Advantages